// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
// import "./controllers"
import * as bootstrap from "bootstrap"

(function () {
  var TIPOFF = TIPOFF || {};

  TIPOFF = {
    init: function () {
      this.markerAnimation();
    },
    /************************************************************
    表示エリアに入ったらマーカーアニメーション
    ************************************************************/

    markerAnimation: function () {
      const markerTargets = document.querySelectorAll('.u-marker-animation');

      const options = {
        // ターゲット要素が50px表示されたら関数を呼び出す
        rootMargin: '-50px 0px'
      };

      // is-showクラスの追加
      function showMarker(entries, observer) {
        entries.forEach(function (entry) {
          // isIntersectingプロパティは交差しているかどうかのbool値
          if (entry.isIntersecting) {
            entry.target.classList.add('is-show');
            observer.unobserve(entry.target); // ターゲットの監視を終了
          }
        })
      }

      // オブザーバーを生成
      var observer = new IntersectionObserver(showMarker, options);

      // 対象の要素を監視
      markerTargets.forEach(function (target) {
        observer.observe(target);
      });
    },
  };
  TIPOFF.init();
})();
